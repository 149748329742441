<template>
  <v-expand-transition>
    <v-card class="mx-auto my-1" elevation="3" v-show="value">
      <v-container v-if="latestParcel">
        <ParcelGalleryDetail
          v-bind="$attrs"
          ref="gallery"
          :parcel-geojson="parcelGeojson"
          :key="latestParcel.number"
          :parcel-id="$route.params.id"
          :parcel="latestParcel.number"
          :parcel-number="latestParcel.number"
          :feature="feature"
          :parcel-data="latestParcel"
          @update:count="onUpdateCount"
          @upload="onUpload"
          @click:land="onClickLand"
        />
      </v-container>
    </v-card>
  </v-expand-transition>
</template>

<script>
import ParcelGalleryDetail from './ParcelGalleryDetail.vue';
import { mapGetters } from 'vuex';
export default {
  props: {
    feature: {
      type: String,
      default: 'Parcel'
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ParcelGalleryDetail
  },
  computed: {
    ...mapGetters(['latestParcel']),
    parcelGeojson() {
      return this.latestParcel?.geojson;
    }
  },
  methods: {
    onClickLand(args) {
      this.$emit('click:land', args);
    },
    onUpload() {
      this.$emit('upload');
    },
    onUpdateCount(value) {
      this.$emit('update:count', value);
    },
    showActionDialog() {
      this.$refs.gallery.showActionDialog();
    }
  }
};
</script>

<style></style>
